<template>
  <div class="error-container d-flex flex-column justify-center align-center fill-height">
    <img src="/img/tayonaute_yellow_circle.png" :alt="$t('alt.tayo_error')">
    <p class="error-title">
      {{ $t('error.generic') }}
    </p>
    <p class="error-status">
      {{ error.statusCode }}
    </p>
    <p>
      {{ error.message }}
    </p>
    <br>

    <div v-if="error.statusCode === 404 || error.statusCode === '404'">
      <p>
        {{ $t('error.lost') }}
      </p>
    </div>
    <!-- Redirect to home page -->
    <v-btn
      target="_blank"
      class="tayo-btn pr-5 mt-6"
      theme="light"
      flat
      rounded="pill"
      aria-label="Go home"
      :prepend-icon="icon"
      height="40px"
      @click="handleError"
    >
      {{ $t('error.go_home') }}
    </v-btn>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useI18n } from 'vue-i18n'
import { mdiArrowRight } from '@mdi/js'
const { t, locale } = useI18n({ useScope: 'global' })

// default props available on error.vue
const props = defineProps({
  error: {
    type: Object,
    default () {
      return {}
    }
  }
})
const icon = mdiArrowRight

const lg = locale && locale.value ? locale.value : 'en'
const handleError = () => clearError({ redirect: '/' + lg })
</script>
<style scoped>
  .error-container{
    background: black;
    color : white;
    height: 100%;
    text-align: center;
    min-height:100vh;
    padding: 0;
    margin: 0;
  }
  .error-title{
    color : #FFCC00;
    font-size: 1.3em;
    line-height: 1.5em;
    font-weight: 700;
    font-family: "MaisonNeue Extended", sans-serif;

  }
  .tayo-btn{
    margin-top:18px;
  }
  .error-container > *{
    margin: 8px 0;
  }
  .error-container img{
    width: 160px;
    margin-bottom: 18px;
  }
  .error-status{
    font-size: 1.3em;
    line-height: 1.5em;
  }
</style>
